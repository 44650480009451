/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Switch, Route, BrowserRouter } from 'react-router-dom';

import { GlobalStyle } from 'styles/global-styles';
import { translations } from '../locales/translations';
import { PathConstant } from './constants/path.constant';
import { ClientPage } from './pages/ClientPage/Loadable';
import { DashboardPage } from './pages/DashboardPage/Loadable';
import { DishPage } from './pages/DishPage/Loadable';
import { ForgotPasswordPage } from './pages/ForgotPasswordPage/Loadable';

import { HomePage } from './pages/HomePage/Loadable';
import { IncreaseRatePage } from './pages/IncreaseRatePage/Loadable';
import { IngredientPage } from './pages/IngredientPage/Loadable';
import { KitchenOrderPage } from './pages/KitchenOrderPage/Loadable';
import { LogHistoryListPage } from './pages/LogHistoryPage/Loadable';
import { LoginPage } from './pages/LoginPage/Loadable';
import { NotFoundPage } from './pages/NotFoundPage/Loadable';
import { OperatorInfoPage } from './pages/OperatorInfoPage/Loadable';
import { ManagerPage } from './pages/ManagerPage/Loadable';
import { PlanPage } from './pages/PlanPage/Loadable';
import { PreparationPage } from './pages/PreparationPage/Loadable';
import { RecipePage } from './pages/RecipePage/Loadable';
import { RootPage } from './pages/RootPage/Loadable';
import { SalesAggregationPage } from './pages/SalesAggregationPage/Loadable';
import { SettingPage } from './pages/SettingPage/Loadable';
import { SettlementInfoPage } from './pages/SettlementInfoPage/Loadable';
import { UserPage } from './pages/UserPage/Loadable';
import { WeeklyImagePage } from './pages/WeeklyImagePage/Loadable';
import { WeeklyMenuPage } from './pages/WeeklyMenuPage/Loadable';
import PrivateSignedInRoute from './routes/private-signed-in.route';
import PrivateSignedOutRoute from './routes/private-signed-out.route';

export function App() {
  const { t } = useTranslation();
  return (
    <BrowserRouter>
      <Helmet
        titleTemplate={`%s - ${t(translations.LABEL.PLATING)}`}
        defaultTitle={t(translations.LABEL.PLATING)}
      >
        <meta name="description" content={t(translations.LABEL.PLATING)} />
      </Helmet>

      <Switch>
        <Route exact path={PathConstant.ROOT} component={RootPage} />
        <PrivateSignedOutRoute
          exact
          path={PathConstant.LOGIN}
          component={LoginPage}
        />
        <PrivateSignedOutRoute
          exact
          path={PathConstant.FORGOT_PASSWORD}
          component={ForgotPasswordPage}
        />
        <PrivateSignedInRoute
          exact
          path={PathConstant.HOME}
          component={() => <HomePage title="" children={<DashboardPage />} />}
        />
        <PrivateSignedInRoute
          exact
          path={PathConstant.INGREDIENT}
          component={() => (
            <HomePage
              title={`${t(translations.LABEL.KITCHEN_TEAM)} - ${t(
                translations.LABEL.INGREDIENT,
              )}`}
              children={<IngredientPage />}
            />
          )}
        />
        <PrivateSignedInRoute
          exact
          path={PathConstant.RECIPE}
          component={() => (
            <HomePage
              title={`${t(translations.LABEL.KITCHEN_TEAM)} - ${t(
                translations.LABEL.RECIPE,
              )}`}
              children={<RecipePage />}
            />
          )}
        />
        {/* <PrivateSignedInRoute
          exact
          path={PathConstant.CUSTOMER_EXPANSION_RATE}
          component={() => (
            <HomePage
              title={`${t(translations.LABEL.KITCHEN_TEAM)} - ${t(
                translations.LABEL.CUSTOMER_EXPANSION_RATE,
              )}`}
              children={<IncreaseRatePage />}
            />
          )}
        /> */}
        {/* <PrivateSignedInRoute
          exact
          path={PathConstant.KITCHEN_PREPARATION}
          component={() => (
            <HomePage
              title={`${t(translations.LABEL.KITCHEN_TEAM)} - ${t(
                translations.LABEL.PREPARATION,
              )}`}
              children={<PreparationPage />}
            />
          )}
        /> */}
        <PrivateSignedInRoute
          exact
          path={PathConstant.KITCHEN_ORDER}
          component={() => (
            <HomePage
              title={`${t(translations.LABEL.KITCHEN_TEAM)} - ${t(
                translations.LABEL.KITCHEN_ORDER,
              )}`}
              children={<KitchenOrderPage />}
            />
          )}
        />
        <PrivateSignedInRoute
          exact
          path={PathConstant.OPERATOR_INFO}
          component={() => (
            <HomePage
              title={`${t(translations.LABEL.OPERATOR_TEAM)} - ${t(
                translations.LABEL.OPERATOR_INFO,
              )}`}
              children={<OperatorInfoPage />}
            />
          )}
        />
        <PrivateSignedInRoute
          exact
          path={PathConstant.MANAGER_INFO}
          component={() => (
            <HomePage
              title={`${t(translations.LABEL.OPERATOR_TEAM)} - ${t(
                translations.LABEL.MANAGER_INFO,
              )}`}
              children={<ManagerPage />}
            />
          )}
        />
        <PrivateSignedInRoute
          exact
          path={PathConstant.WEEKLY_MENU}
          component={() => (
            <HomePage
              title={`${t(translations.LABEL.OPERATOR_TEAM)} - ${t(
                translations.LABEL.WEEKLY_MENU,
              )}`}
              children={<WeeklyMenuPage />}
            />
          )}
        />
        <PrivateSignedInRoute
          exact
          path={PathConstant.WEEKLY_MENU_IMAGE}
          component={() => (
            <HomePage
              title={`${t(translations.LABEL.OPERATOR_TEAM)} - ${t(
                translations.LABEL.WEEKLY_MENU_IMAGE,
              )}`}
              children={<WeeklyImagePage />}
            />
          )}
        />
        <PrivateSignedInRoute
          exact
          path={PathConstant.LOG_HISTORY}
          component={() => (
            <HomePage
              title={t(translations.LABEL.LOG_HISTORY)}
              children={<LogHistoryListPage />}
            />
          )}
        />
        <PrivateSignedInRoute
          exact
          path={PathConstant.USER}
          component={() => (
            <HomePage
              title={`${t(translations.LABEL.OPERATOR_TEAM)} - ${t(
                translations.LABEL.CUSTOMER_REPRESENTATIVE,
              )}`}
              children={<UserPage />}
            />
          )}
        />
        <PrivateSignedInRoute
          exact
          path={PathConstant.CUSTOMER}
          component={() => (
            <HomePage
              title={`${t(translations.LABEL.OPERATOR_TEAM)} - ${t(
                translations.LABEL.CUSTOMER,
              )} ${t(translations.LABEL.LIST)}`}
              children={<ClientPage />}
            />
          )}
        />
        <PrivateSignedInRoute
          exact
          path={PathConstant.DISH}
          component={() => (
            <HomePage
              title={`${t(translations.LABEL.KITCHEN_TEAM)} - ${t(
                translations.LABEL.MENU,
              )}`}
              children={<DishPage />}
            />
          )}
        />
        {/* <PrivateSignedInRoute
          exact
          path={PathConstant.STATEMENT_ACCOUNT}
          component={() => (
            <HomePage
              title={`${t(translations.LABEL.FINANCE_TEAM)} - ${t(
                translations.LABEL.STATEMENT_ACCOUNT,
              )}`}
              children={<SettlementInfoPage />}
            />
          )}
        /> */}
        <PrivateSignedInRoute
          exact
          path={PathConstant.SALES_AGGREGATION}
          component={() => (
            <HomePage
              title={`${t(translations.LABEL.FINANCE_TEAM)} - ${t(
                translations.LABEL.SALES_AGGREGATION,
              )}`}
              children={<SalesAggregationPage />}
            />
          )}
        />
        <PrivateSignedInRoute
          exact
          path={PathConstant.PLAN}
          component={() => (
            <HomePage
              title={`${t(translations.LABEL.FINANCE_TEAM)} - ${t(
                translations.LABEL.PLAN_PRICE,
              )}`}
              children={<PlanPage />}
            />
          )}
        />
        <PrivateSignedInRoute
          exact
          path={PathConstant.SETTING}
          component={() => (
            <HomePage
              title={t(translations.LABEL.SETTING)}
              children={<SettingPage />}
            />
          )}
        />
        <Route component={NotFoundPage} />
      </Switch>
      <GlobalStyle />
    </BrowserRouter>
  );
}
