import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import { useInjectReducer } from '../../utils/redux-injectors';
import { NotFoundPage } from '../pages/NotFoundPage/Loadable';
import {
  selectIsLoggedIn,
  selectLoggedInUser,
} from '../selectors/auth.selector';
import {
  reducer as authReducer,
  sliceKey as authSliceKey,
} from '../slices/auth.slice';

const PrivateSignedInRoute = ({ component: Component, ...rest }) => {
  useInjectReducer({ key: authSliceKey, reducer: authReducer });
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const user = useSelector(selectLoggedInUser);

  return (
    <Route
      {...rest}
      render={props => {
        return isLoggedIn && user ? <Component {...props} /> : <NotFoundPage />;
      }}
    />
  );
};

export default PrivateSignedInRoute;
