import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import { useInjectReducer } from '../../utils/redux-injectors';
import { NotFoundPage } from '../pages/NotFoundPage/Loadable';
import { selectIsLoggedIn } from '../selectors/auth.selector';
import {
  reducer as authReducer,
  sliceKey as authSliceKey,
} from '../slices/auth.slice';

const PrivateSignedOutRoute = ({ component: Component, ...rest }) => {
  useInjectReducer({ key: authSliceKey, reducer: authReducer });
  const isLoggedIn = useSelector(selectIsLoggedIn);

  return (
    <Route
      {...rest}
      render={props => {
        return !isLoggedIn ? <Component {...props} /> : <NotFoundPage />;
      }}
    />
  );
};

export default PrivateSignedOutRoute;
