import { PayloadAction } from '@reduxjs/toolkit';
import addDays from 'date-fns/addDays';
import Cookies from 'universal-cookie';
import { createSlice } from '../../utils/@reduxjs/toolkit';
import { PathConstant } from '../constants/path.constant';
import { SettingConstant } from '../constants/setting.constant';
import User from '../models/user.model';
import { ContainerState } from '../types/auth.type';

const cookies = new Cookies();

export const initialState: ContainerState = {
  isLoggedIn: cookies.get(SettingConstant.IS_LOGGED_IN_COOKIE_NAME) || false,
  user: cookies.get(SettingConstant.USER_ADMIN_COOKIE_NAME) || null,
};

const authSlice = createSlice({
  name: 'authState',
  initialState,
  reducers: {
    loggedIn(state, action: PayloadAction<User | null>) {
      state.isLoggedIn = true;
      state.user = action.payload;
      cookies.set(SettingConstant.IS_LOGGED_IN_COOKIE_NAME, true, {
        path: PathConstant.ROOT,
        expires: addDays(new Date(), 1),
      });
      cookies.set(SettingConstant.USER_ADMIN_COOKIE_NAME, action.payload, {
        path: PathConstant.ROOT,
        expires: addDays(new Date(), 1),
      });
    },
    loggedOut(state) {
      state.isLoggedIn = false;
      state.user = null;
      cookies.remove(SettingConstant.IS_LOGGED_IN_COOKIE_NAME);
      cookies.remove(SettingConstant.USER_ADMIN_COOKIE_NAME);
    },
    resetState(state) {
      state.isLoggedIn = initialState.isLoggedIn;
      state.user = initialState.user;
      cookies.remove(SettingConstant.IS_LOGGED_IN_COOKIE_NAME);
      cookies.remove(SettingConstant.USER_ADMIN_COOKIE_NAME);
    },
  },
});

export const { actions, reducer, name: sliceKey } = authSlice;
