export class SettingConstant {
  public static readonly ANALYTICS_YEAR_MONTH_FORMAT = 'yyyy/MM';
  public static readonly ANALYTICS_DATE_FORMAT = 'MM/dd';
  public static readonly CATERING_CONTAINER = [
    '호텔팬',
    '압축국통',
    '서드',
    '하프',
  ];
  public static readonly CATERING_PRICE = 10000;
  public static readonly DATA_LIMIT_PER_PAGE = 10;
  public static readonly DATE_PICKER_MASK = '____-__-__';
  public static readonly DOSHIRAK_PRICE = 8000;
  public static readonly GOOGLE_CLIENT_ID =
    '711275887206-f4s8b8s8nli2i65l87b037q4fc66980b.apps.googleusercontent.com';
  public static readonly GOOGLE_MAPS_API_KEY =
    'AIzaSyAXo9f92rkKU7ruqWsHLBTtYJ-4wVwvP18';
  public static readonly IS_LOGGED_IN_COOKIE_NAME = 'isLoggedIn';
  public static readonly LOCALE_OPTION = {
    maximumFractionDigits: 2,
  };
  public static readonly MAX_FILE_SIZE = 0.3;
  public static readonly MILLISECONDS = 1000;
  public static readonly MONTH_DATE_FORMAT = 'MM.dd';
  public static readonly REGEX_ALPHABET_KOREAN = new RegExp(
    /^[a-zA-Z\u3131-\uD79D ]+$/iu,
  );
  public static readonly REGEX_NUMERIC_KOREAN = new RegExp(
    /^[0-9\u3131-\uD79D ]+$/iu,
  );
  public static readonly REGEX_ALPHANUMERIC_SYMBOL_KOREAN = new RegExp(
    /^[0-9a-zA-Z\u3131-\uD79D ()-]+$/iu,
  );
  public static readonly REGEX_EMAIL = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
  );
  public static readonly REGEX_KOREAN_BUSINESS_NUMBER = new RegExp(
    /^[0-9]{10}$/,
  );
  public static readonly REGEX_KOREAN_PHONE_NUMBER = new RegExp(
    /^[0-9]{11,15}$/,
  );
  public static readonly REGEX_PASSWORD = new RegExp(
    /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$&()\\-`.+,/"])(?:([a-zA-Z0-9!@#$&()\\-`.+,/"])(?!\1{3})){8,16}$/,
  );
  public static readonly SHORT_YEAR_MONTH_DATE_FORMAT = 'yy.MM.dd';
  public static readonly SIDEBAR_DRAWER_WIDTH = 240;
  public static readonly SIMPLE_DATE_FORMAT = 'yyyy-MM-dd';
  public static readonly SIMPLE_DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm:ss';
  public static readonly SIMPLE_DATE_TIME_FORMAT_WITHOUT_SECOND =
    'yyyy-MM-dd HH:mm';
  public static readonly SIMPLE_DATE_TIME_KOREA_FORMAT =
    'yyyy-MM-dd 오전 HH시 mm분';
  public static readonly SIMPLE_MONTH_DATE_FORMAT = 'MM/dd';
  public static readonly SIMPLE_MONTH_YEAR_KOREA_FORMAT = 'yyyy년 MM월';
  public static readonly SIMPLE_DATE_KOREA_FORMAT = 'yyyy년 MM월 dd일';
  public static readonly SIMPLE_TIME_FORMAT = 'HH:mm:ss';
  public static readonly SIMPLE_TIME_FORMAT_SHORTENED = 'HH:mm';
  public static readonly USER_ADMIN_COOKIE_NAME = 'userAdmin';
}
