export class PathConstant {
  public static readonly ROOT = '/';

  public static readonly CUSTOMER = '/customer';
  // public static readonly CUSTOMER_EXPANSION_RATE = '/customer-expansion-rate';
  public static readonly DISH = '/dish';
  public static readonly FORGOT_PASSWORD = '/forgot-password';
  public static readonly HOME = '/home';
  public static readonly INGREDIENT = '/ingredient';
  public static readonly KITCHEN_ORDER = '/kitchen-order';
  // public static readonly KITCHEN_PREPARATION = '/kitchen-preparation';
  public static readonly MANAGER_INFO = '/manager-info';
  public static readonly LOG_HISTORY = '/log-history';
  public static readonly LOGIN = '/login';
  public static readonly OPERATOR_INFO = '/operator-info';
  public static readonly PLAN = '/plan';
  public static readonly RECIPE = '/recipe';
  public static readonly SALES_AGGREGATION = '/sales-aggregation';
  public static readonly SETTING = '/setting';
  // public static readonly STATEMENT_ACCOUNT = '/statement-account';
  public static readonly USER = '/user';
  public static readonly WEEKLY_MENU = '/weekly-menu';
  public static readonly WEEKLY_MENU_IMAGE = '/weekly-menu-image';
}
